<template>
    <div v-if="hasRole('Administrator') || hasRole('Einkäufer') || hasRole('Erweiterter Einkäufer') || hasRole('Berater')" class="card" style="border-width: 0px" v-show="doneLoading">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            <router-link :to="'/app/auktionen'" href="#">{{editType}} {{ $t("cfbcreate.header") }} </router-link>
            <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ auction.title }}
            <router-link v-if="hasRole('Administrator')||hasRole('Einkäufer')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" :to="'/app/auktionen/view/'+auction.id" href="#"><b-icon icon="eye" class="ml-1" scale="1.3"></b-icon></router-link>
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-form @submit.prevent="onSubmit" v-show="doneLoading">
                <b-tabs>
                    <b-tab :title="$t(`cfbcreate.tab_masterdata`)" active>
                        <b-card-group columns>
                            <b-overlay :show="auction.basedata_checked" rounded="lg">
                                <b-card :title="$t(`cfbcreate.card_data`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                    <b-card-text>
                                        <b-form-group :label="$t(`cfbcreate.auction_title`)" label-for="auction-title">
                                            <b-form-input id="auction-title" type="text" v-model="auction.title" :state="!$v.auction.title.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>

                                        <b-form-group :label="$t(`cfbcreate.auction_organizer`)" label-for="auction-organizer">
                                            <b-form-select text-field="display_name" value-field="id" :options="organizers" v-model="selectedOrganizer" :state="!$v.selectedOrganizer.$error ? null : false" style="background-color: #fdfdfd"></b-form-select>
                                        </b-form-group>

                                        <b-form-group :label="$t(`cfbcreate.auction_start`)" label-for="auction-start">
                                            <b-row>
                                                <b-col>
                                                    <b-form-datepicker today-variant="info" v-model="auction.start_date_date" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="auction-start" :placeholder="$t(`common.nodateselected`)" :state="!$v.auction.start_date.$error ? null : false">
                                                    </b-form-datepicker>
                                                </b-col>
                                                <b-col>
                                                    <b-form-timepicker v-model="auction.start_date_time" :locale="$i18n.locale" :state="!$v.auction.start_date.$error ? null : false">
                                                    </b-form-timepicker>
                                                </b-col>
                                            </b-row>
                                        </b-form-group>

                                        <b-form-group :label="$t(`cfbcreate.auction_end`)" label-for="auction-end">
                                            <b-row>
                                                <b-col>
                                                    <b-form-datepicker today-variant="info" v-model="auction.end_date_date" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="auction-end" :placeholder="$t(`common.nodateselected`)" :state="!$v.auction.end_date.$error ? null : false">
                                                    </b-form-datepicker>
                                                </b-col>
                                                <b-col>
                                                    <b-form-timepicker v-model="auction.end_date_time" :locale="$i18n.locale" :state="!$v.auction.end_date.$error ? null : false">
                                                    </b-form-timepicker>
                                                </b-col>
                                            </b-row>
                                        </b-form-group>

                                        <b-form-group v-if="isAuction" :label="$t(`cfbcreate.auction_precision`)" label-for="auction-precision">
                                            <b-form-input id="auction-precision" type="text" required v-model="auction.precision" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-card-text>

                                    <template #footer>
                                        <b-form-checkbox v-model="auction.basedata_checked" name="check-button" switch>
                                            Basisdaten vollständig...
                                        </b-form-checkbox>
                                    </template>
                                </b-card>

                                <template #overlay>
                                    <div class="text-center">
                                        <b-icon icon="check-square" font-scale="2"></b-icon>
                                        <b-form-checkbox v-model="auction.basedata_checked" name="switch" switch>
                                            Basisdaten vollständig...
                                        </b-form-checkbox>
                                    </div>
                                </template>
                            </b-overlay>
    
                            <b-overlay :show="auction.auctionparameter_checked" rounded="lg">
                                <b-card :title="$t(`cfbcreate.card_parameter`)" v-if="isAuction" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                    <b-card-text>
                                        <b-button class="button-margin" variant="outline-danger" v-b-toggle.auctionparameter><v-icon name="gavel" /> {{ $t("cfbcreate.btn_auction_edit") }}</b-button>
                                        <div style="position: absolute; right: 1rem; top: 1rem" v-if="auction.ranking_visible === true || auction.ranking_position_visible === true || auction.lowest_bid_visible === true">
                                            <b-badge variant="danger">{{ $t("cfbcreate.auction_selected") }}</b-badge>
                                        </div>
                                        <b-collapse id="auctionparameter" class="mt-3">
                                            <b-form-group :label="$t(`cfbcreate.auction_extendafterbid`)" label-for="extend-after-bid" v-if="isAuction">
                                                <b-form-spinbutton min="0" max="180" id="auction-extend-after-bid" class="mx-1" inline :state="isAuction && !$v.auction.extend_after_bid.$error ? null : false" v-model="auction.extend_after_bid" style="background-color: #fdfdfd"></b-form-spinbutton>
                                                <label for="auction-extend-after-bid">{{ $t("cfbcreate.auction_extendafterbid_unit") }}</label>
                                            </b-form-group>

                                            <b-form-group label="" label-for="auction-ranking-visible">
                                                <b-form-checkbox v-b-popover.hover.left="'Die Preisfindung erfolgt hierbei in einem Auktionsformat!'" title="Inverse Rangauktion" id="auction-ranking-visible" v-model="auction.ranking_visible"> {{ $t("cfbcreate.auction_rankingvisible") }}</b-form-checkbox>
                                                <div v-if="auction.ranking_visible === false">{{ $t("cfbcreate.auction_noranking") }}</div>
                                                <div v-if="auction.ranking_visible === true"><div style="color: #e3342f">{{ $t("cfbcreate.auction_totalranking_active") }}</div>{{ $t("cfbcreate.auction_totalranking_note") }}</div>
                                            </b-form-group>

                                            <b-form-group label="" label-for="auction-ranking-position-visible">
                                                <b-form-checkbox v-b-popover.hover.left="'Die Preisfindung erfolgt hierbei in einem Auktionsformat!'" title="Inverse Rangauktion" id="auction-ranking-position-visible" v-model="auction.ranking_position_visible"> {{ $t("cfbcreate.auction_rankingpositionvisible") }}</b-form-checkbox>
                                                <div v-if="auction.ranking_position_visible === false">{{ $t("cfbcreate.auction_noranking") }}</div>
                                                <div v-if="auction.ranking_position_visible === true"><div style="color: #e3342f">{{ $t("cfbcreate.auction_ranking_active") }}</div>{{ $t("cfbcreate.auction_ranking_note") }}</div>
                                            </b-form-group>

                                            <b-form-group label="" label-for="auction-lowest-bid-visible">
                                                <b-form-checkbox v-b-popover.hover.left="'Die Preisfindung erfolgt hierbei in einem Auktionsformat!'" title="Inverse Auktion" id="auction-lowest-bid-visible" v-model="auction.lowest_bid_visible"> {{ $t("cfbcreate.auction_lowestbidvisible") }}</b-form-checkbox>
                                                <div v-if="auction.lowest_bid_visible === false">Keine Auktion eingestellt.</div>
                                                <div v-if="auction.lowest_bid_visible === true"><div style="color: #e3342f">{{ $t("cfbcreate.auction_selected") }}</div>{{ $t("cfbcreate.auction_selected_note") }}</div>
                                            </b-form-group>

                                            <b-button class="button-margin" type="submit" variant="outline-danger"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
                                        </b-collapse>
                                    </b-card-text>

                                    <template #footer>
                                        <b-form-checkbox v-model="auction.auctionparameter_checked" name="check-button" switch>
                                            Auktionsparameter zugewiesen...
                                        </b-form-checkbox>
                                    </template>
                                </b-card>

                                <template #overlay>
                                    <div class="text-center">
                                        <b-icon icon="check-square" font-scale="2"></b-icon>
                                        <b-form-checkbox v-model="auction.auctionparameter_checked" name="switch" switch>
                                            Auktionsparameter zugewiesen...
                                        </b-form-checkbox>
                                    </div>
                                </template>
                            </b-overlay>

                            <b-overlay :show="auction.bidimport_checked" rounded="lg">
                                <b-card :title="$t(`cfbcreate.card_bid_import`)" :sub-title="$t(`cfbcreate.card_subtitle_bidimport`)" v-if="isAuction" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                    <b-card-text>
                                        <b-form-group label="" label-for="bid-import-allow">
                                            <b-form-checkbox id="bid-import-allow" v-model="auction.bid_import_allow"> {{ $t("cfbcreate.bid_import_allow") }}</b-form-checkbox>
                                        </b-form-group>

                                        <b-form-group :label="$t(`cfbcreate.bid_import_sheet`)" label-for="bid-import-sheet">
                                            <b-form-input id="bid-import-sheet" type="text" v-model="auction.bid_import_sheet" :placeholder="$t(`cfbcreate.placeholder_bid_import_sheet`)" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>

                                        <b-form-group :label="$t(`cfbcreate.bid_import_header_row`)" label-for="bid-import-header-row">
                                            <b-form-input id="bid-import-header-row" type="text" v-model="auction.bid_import_header_row" :placeholder="$t(`cfbcreate.placeholder_bid_import_header_row`)" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>

                                        <b-form-group :label="$t(`cfbcreate.bid_import_field_item`)" label-for="bid-import-field-item">
                                            <b-form-input id="bid-import-field-item" type="text" v-model="auction.bid_import_field_item" :placeholder="$t(`cfbcreate.placeholder_bid_import_field_item`)" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>

                                        <b-form-group :label="$t(`cfbcreate.bid_import_field_bid`)" label-for="bid-import-field-bid">
                                            <b-form-input id="bid-import-field-bid" type="text" v-model="auction.bid_import_field_bid" :placeholder="$t(`cfbcreate.placeholder_bid_import_field_bid`)" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-card-text>
                                    <template #footer>
                                        <b-form-checkbox v-model="auction.bidimport_checked" name="check-button" switch>
                                            Importparameter zugewiesen...
                                        </b-form-checkbox>
                                    </template>    
                                </b-card>

                                <template #overlay>
                                    <div class="text-center">
                                        <b-icon icon="check-square" font-scale="2"></b-icon>
                                        <b-form-checkbox v-model="auction.bidimport_checked" name="switch" switch>
                                            Importparameter zugewiesen...
                                        </b-form-checkbox>
                                    </div>
                                </template>
                            </b-overlay>   

                                <b-card :title="$t(`cfbcreate.card_price_alert`)" :sub-title="$t(`cfbcreate.card_subtitle_price_alert`)" v-if="isAuction" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                    <b-card-text>
                                        
                                        <b-form-group label="" label-for="price-alert-active">
                                            <b-form-checkbox id="price-alert-active" v-model="auction.price_alert_active"> {{ $t("cfbcreate.price_alert_active") }}</b-form-checkbox>
                                        </b-form-group>
                                        <b-form-group label="" label-for="price-alert-visible">
                                            <b-form-checkbox id="price-alert-visible" v-model="auction.price_alert_visible"> {{ $t("cfbcreate.price_alert_visible") }}</b-form-checkbox>
                                        </b-form-group>

                                        <div v-if="auction.price_alert_active === true">
                                            <b-form-group :label="$t(`cfbcreate.price_alert_title`)" label-for="price-alert-title">
                                                <b-form-input id="price-alert-title" type="text" v-model="auction.price_alert_title" :placeholder="$t(`cfbcreate.placeholder_price_alert_title`)" style="background-color: #fdfdfd"></b-form-input>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.price_alert_description`)" label-for="price-alert-description">
                                                <b-form-textarea v-model="auction.price_alert_description" id="price-alert-description" :no-resize="false" :rows="3" style="background-color: #fdfdfd"></b-form-textarea>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.price_alert_recipient_1`)" label-for="price-alert-recipient-1">
                                                <b-form-select id="price-alert-recipient-1" text-field="name" value-field="id" :options="users" v-model="auction.price_alert_recipient_1"></b-form-select>
                                            </b-form-group>
                                        
                                            <b-form-group :label="$t(`cfbcreate.price_alert_recipient_2`)" label-for="price-alert-recipient-2">
                                                <b-form-select id="price-alert-recipient-2" text-field="name" value-field="id" :options="users" v-model="auction.price_alert_recipient_2"></b-form-select>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.price_alert_recipient_3`)" label-for="price-alert-recipient-3">
                                                <b-form-select id="price-alert-recipient-3" text-field="name" value-field="id" :options="users" v-model="auction.price_alert_recipient_3"></b-form-select>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.price_alert_startdate`)" label-for="price-alert-startdate">
                                                <b-form-datepicker today-variant="info" v-model="auction.price_alert_startdate" :locale="$i18n.locale" start-weekday="1" id="price-alert-startdate" :placeholder="$t(`common.nodateselected`)">
                                                </b-form-datepicker>
                                            </b-form-group>
                                            <b-form-group :label="$t(`cfbcreate.price_alert_enddate`)" label-for="price-alert-enddate">
                                                <b-form-datepicker today-variant="info" v-model="auction.price_alert_enddate" :locale="$i18n.locale" start-weekday="1" id="price-alert-enddate" :placeholder="$t(`common.nodateselected`)">
                                                </b-form-datepicker>
                                            </b-form-group>
                                        </div>
                                    </b-card-text>   
                                </b-card>
    
                            <b-overlay :show="auction.conditions_checked" rounded="lg">
                                <b-card :title="this.isAuction ? this.$t(`cfbcreate.card_conditions`) : this.$t(`cfbcreate.card_pq_conditions`)" :sub-title="$t(`cfbcreate.card_conditions_subtitle`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                    <b-card-text>
                                        <b-form-group :label="$t(`cfbcreate.auction_contact`)" class="d-flex justify-content-between mb-0" label-for="auction-contact">
                                            <b-button v-b-toggle.help_contact pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                        </b-form-group>
                                        <b-form-textarea v-model="auction.contact" id="auction-contact" class="mb-3" :no-resize="true" :rows="3" :max-rows="3" :state="!$v.auction.contact.$error ? null : false" style="background-color: #fdfdfd"></b-form-textarea>
                                        <b-sidebar right id="help_contact" :title="$t(`cfbcreate.auction_contact`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                            <div class="px-3 py-2">
                                                <b-list-group>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.contact_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.contact_1`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.contact_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.contact_2`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.contact_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.contact_3`))"></b-icon></b-list-group-item>
                                                </b-list-group>
                                            </div>
                                        </b-sidebar>

                                        <b-form-group :label="$t(`cfbcreate.auction_techcontact`)" class="d-flex justify-content-between mb-0" label-for="auction-technical-contact">
                                            <b-button v-b-toggle.help_technicalcontact pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                        </b-form-group>
                                        <b-form-textarea v-model="auction.technical_contact" id="auction-technical-contact" class="mb-3" :no-resize="true" :rows="3" :max-rows="3" style="background-color: #fdfdfd"></b-form-textarea>
                                        <b-sidebar right id="help_technicalcontact" :title="$t(`cfbcreate.auction_techcontact`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                            <div class="px-3 py-2">
                                                <b-list-group>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.technicalcontact_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.technicalcontact_1`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.technicalcontact_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.technicalcontact_2`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.technicalcontact_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.technicalcontact_3`))"></b-icon></b-list-group-item>                                                
                                                </b-list-group>
                                            </div>
                                        </b-sidebar>

                                        <div v-if="isAuction">
                                            <b-form-group :label="$t(`cfbcreate.auction_deliveryconditions`)" class="d-flex justify-content-between mb-0" label-for="auction-delivery-conditions">
                                                <b-button v-b-toggle.help_incoterms pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                            </b-form-group>
                                            <b-form-textarea v-model="auction.delivery_conditions" id="auction-delivery-conditions" class="mb-3" :no-resize="true" :rows="3" :max-rows="3" style="background-color: #fdfdfd"></b-form-textarea>
                                            <b-sidebar right id="help_incoterms" :title="$t(`help.title_incoterms`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                                <div class="px-3 py-2">
                                                    <b-list-group>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_1`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_2`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_3`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_4`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_5") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_5`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_6") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_6`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_7") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_7`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_8") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_8`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_9") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_9`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_10") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_10`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.incoterms_11") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.incoterms_11`))"></b-icon></b-list-group-item>                                                
                                                    </b-list-group>
                                                </div>
                                            </b-sidebar>
                                        </div>

                                        <div v-if="isAuction">
                                            <b-form-group :label="$t(`cfbcreate.auction_paymentconditions`)" class="d-flex justify-content-between mb-0" label-for="auction-payment-conditions">
                                                <b-button v-b-toggle.help_payment pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                            </b-form-group>
                                            <b-form-textarea v-model="auction.payment_conditions" id="auction-payment-conditions" class="mb-3" :no-resize="true" :rows="1" :max-rows="1" style="background-color: #fdfdfd"></b-form-textarea>
                                            <b-sidebar right id="help_payment" :title="$t(`help.title_paymentconditions`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                                <div class="px-3 py-2">
                                                    <b-list-group>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.payment_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.payment_1`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.payment_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.payment_2`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.payment_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.payment_3`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.payment_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.payment_4`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.payment_5") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.payment_5`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.payment_6") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.payment_6`))"></b-icon></b-list-group-item>
                                                        <b-list-group-item button class="d-flex align-items-center">{{ $t("help.payment_7") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.payment_7`))"></b-icon></b-list-group-item>
                                                    </b-list-group>
                                                </div>
                                            </b-sidebar>
                                        </div>

                                        <b-form-group :label="$t(`cfbcreate.auction_conditions`)" label-for="auction-conditions">
                                            <b-form-textarea v-model="auction.conditions" id="auction-conditions" :no-resize="false" :rows="5" style="background-color: #fdfdfd"></b-form-textarea>
                                        </b-form-group>
                                    </b-card-text>
                                    <template #footer>
                                        <b-form-checkbox v-model="auction.conditions_checked" name="check-button" switch>
                                            Vergabebedingungen vollständig beschrieben...
                                        </b-form-checkbox>
                                    </template>   
                                </b-card>
                                <template #overlay>
                                    <div class="text-center">
                                        <b-icon icon="check-square" font-scale="2"></b-icon>
                                        <b-form-checkbox v-model="auction.conditions_checked" name="switch" switch>
                                            Vergabebedingungen vollständig beschrieben...
                                        </b-form-checkbox>
                                    </div>
                                </template>
                            </b-overlay>
                            
                            <b-card :title="$t(`cfbcreate.card_assigning`)" :sub-title="$t(`cfbcreate.card_intern_subtitle_assigning`)" class="mb-3" style="background-color: #f4f5fa">
                                <b-card-text>
                                    <b-form-group :label="$t(`cfbcreate.auction_status`)" label-for="auction-status">
                                        <b-form-select v-model="auction.status" class="mb-3" style="background-color: #fdfdfd">
                                        <option value="0">{{ $t("cfbcreate.status_0") }}</option>
                                        <option value="1">{{ $t("cfbcreate.status_1") }}</option>
                                        <option value="2">{{ $t("cfbcreate.status_2") }}</option>
                                        <option value="3">{{ $t("cfbcreate.status_3") }}</option>
                                        </b-form-select>
                                    </b-form-group>

                                    <b-form-group label="" label-for="auction-uploads">
                                        <b-form-checkbox id="auction-uploads" v-model="auction.uploads_active" inline> {{ $t("cfbcreate.auction_uploads") }}</b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group label="" label-for="auction-allow-price-increase">
                                        <b-form-checkbox id="auction-allow-price-increase" v-model="auction.allow_price_increase"> {{ $t("cfbcreate.auction_allowpriceincrease") }}</b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group label="" label-for="auction-summary-first">
                                        <b-form-checkbox id="auction-summary-first" v-model="auction.summary_first"> {{ $t("cfbcreate.auction_summaryfirst") }}</b-form-checkbox>
                                    </b-form-group>
                                    
                                    <b-button class="button-margin mb-4" variant="outline-primary" type="submit"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
                                    
                                    <b-form-group :label="$t(`cfbcreate.auction_processstatus`)" label-for="auction-process-status">
                                        <b-form-select v-model="auction.process_status" class="mb-3" style="background-color: #fdfdfd">
                                        <option value="0">{{ $t("cfbcreate.processstatus_0") }}</option>
                                        <option value="1">{{editType}} {{ $t("cfbcreate.processstatus_1") }}</option>
                                        <option value="2" v-if="isAuction">{{editType}} {{ $t("cfbcreate.processstatus_2") }}</option>
                                        <option value="3">{{editType}} {{ $t("cfbcreate.processstatus_3") }}</option>
                                        <option value="4">{{ $t("cfbcreate.processstatus_4") }}</option>
                                        <option value="5">{{ $t("cfbcreate.processstatus_5") }}</option>
                                        <option value="6">{{ $t("cfbcreate.processstatus_6") }}</option>
                                        <option value="7">{{ $t("cfbcreate.processstatus_7") }}</option>
                                        <option value="8">{{ $t("cfbcreate.processstatus_8") }}</option>
                                        </b-form-select>
                                    </b-form-group>


                                    <b-form-group :label="$t(`cfbcreate.auction_samplingstatus`)" label-for="auction-sampling-status">
                                            <b-form-select v-model="auction.sampling_status" class="mb-3" style="background-color: #fdfdfd">
                                            <option value="0">{{ $t("cfbcreate.samplingstatus_0") }}</option>
                                            <option value="1">{{ $t("cfbcreate.samplingstatus_1") }}</option>
                                            <option value="2">{{ $t("cfbcreate.samplingstatus_2") }}</option>
                                            <option value="3">{{ $t("cfbcreate.samplingstatus_3") }}</option>
                                            <option value="4">{{ $t("cfbcreate.samplingstatus_4") }}</option>
                                            <option value="5">{{ $t("cfbcreate.samplingstatus_5") }}</option>
                                            </b-form-select>
                                    </b-form-group>

                                    <b-form-group :label="$t(`cfbcreate.contractnumber`)" label-for="auction-contract-number">
                                        <b-form-input id="auction-contract-number" type="text" v-model="auction.contract_number" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>

                                    <b-form-group :label="$t(`cfbcreate.purchasing_volume`)" label-for="purchasing-volume">
                                        <b-form-input id="purchasing-volume" type="text" v-model="auction.purchasing_volume" :placeholder="$t(`cfbcreate.placeholder_purchasing_volume`)" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>

                                    <b-form-group :label="$t(`cfbcreate.savings`)" label-for="savings">
                                        <b-form-input id="savings" type="text" v-model="auction.savings" :placeholder="$t(`cfbcreate.placeholder_savings`)" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>

                                    <b-form-group :label="$t(`cfbcreate.auction_notes`)" label-for="auction-notes">
                                        <b-form-textarea v-model="auction.notes" id="auction-notes" :no-resize="false" :rows="11" :max-rows="20" style="background-color: #fdfdfd"></b-form-textarea>
                                    </b-form-group>
                                </b-card-text>
                            </b-card>

                        </b-card-group>
                    </b-tab>

                    <b-tab v-if="isAuction" class="mb-4" :title="$t(`cfbcreate.tab_items`)" :disabled="!editMode">
                        <b-overlay :show="auction.items_checked" rounded="lg">
                            <b-card :title="$t(`cfbcreate.card_items`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-button class="button-margin" variant="primary" @click="showNewItemForm"><b-icon icon="bag-plus" />{{ $t("cfbcreate.btn_newitem") }}</b-button>
                                <b-button class="button-margin" variant="outline-primary" @click.prevent="showImport" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
                                    <b-iconstack>
                                        <b-icon stacked icon="bag"></b-icon>
                                        <b-icon stacked icon="arrow-down" shift-h="" shift-v="-2" scale="0.5"></b-icon>
                                    </b-iconstack>
                                    {{ $t("cfbcreate.btn_itemimport") }}
                                </b-button>
                                <div class="table-scrollable">
                                    <b-table striped hover :items="auction.items" :fields="itemTableFields" sort-by="order">
                                        <template #cell(actions)="data">
                                            <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.order)"><b-icon icon="chevron-up" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" v-show="data.index < auction.items.length-1" @click="moveItemDown(data.item.order)"><b-icon icon="chevron-down" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" @click.prevent="editAuctionItem(data.item)"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" @click.prevent="requestDeleteAuctionItem(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                        </template>
                                        <template #cell(actual_value)="data">
                                            {{toCurrency(data.item.actual_value, auction.precision)}}
                                        </template>
                                        <template #cell(price_alert_value_buyer)="data">
                                            {{toCurrency(data.item.price_alert_value_buyer, auction.precision)}}
                                        </template>
                                        <template #cell(price_alert_value_supplier)="data">
                                            {{toCurrency(data.item.price_alert_value_supplier, auction.precision)}}
                                        </template>
                                        <template #cell(lowest_bid)="data">
                                            {{toCurrency(data.item.lowest_bid, auction.precision)}}
                                        </template>
                                    </b-table>
                                </div>
                                <template #footer>
                                    <b-form-checkbox v-model="auction.items_checked" name="check-button" switch>
                                        Positionen vollständig und korrekt beschrieben...
                                    </b-form-checkbox>
                                </template>
                            </b-card>

                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.items_checked" name="switch" switch>
                                        Positionen vollständig und korrekt beschrieben...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>

                    </b-tab>

                    <b-tab :title="this.isAuction ? this.$t(`cfbcreate.tab_tenderdocuments`) : this.$t(`cfbcreate.tab_documents`)" :disabled="!editMode">
                        <b-overlay :show="auction.documents_checked" rounded="lg">
                            <b-card :title="this.isAuction ? this.$t(`cfbcreate.card_documents`) : this.$t(`cfbcreate.card_pq_documents`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-button class="button-margin" variant="primary" @click="showFileForm"><b-icon icon="file-earmark-plus" />{{ $t("cfbcreate.btn_newfile") }}</b-button>
                                <div class="table-scrollable">
                                    <b-table striped hover :items="auction.files" :fields="fileTableFields" sort-by="order">
                                        <template #cell(actions)="data">
                                            <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveFileUp(data.item.id)"><b-icon icon="chevron-up" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" v-show="data.index < auction.files.length-1" @click="moveFileDown(data.item.id)"><b-icon icon="chevron-down" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" @click="deleteFile(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" @click="downloadFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" @click="editFile(data.item)"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                                        </template>
                                    </b-table>
                                </div>
                                <template #footer>
                                    <b-form-checkbox v-model="auction.documents_checked" name="check-button" switch>
                                        Dokumente final erstellt und vollständig zugewiesen...
                                    </b-form-checkbox>
                                </template>
                            </b-card>
                            
                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.documents_checked" name="switch" switch>
                                        Dokumente final erstellt und vollständig zugewiesen...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>
                    </b-tab>

                    <b-tab :title="this.isAuction ? this.$t(`cfbcreate.tab_user`) : this.$t(`cfbcreate.tab_pq_user`)" :disabled="!editMode">
                        <b-overlay :show="auction.userlist_checked" rounded="lg">
                            <b-card :title="$t(`cfbcreate.card_user`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <div class="table-scrollable">
                                    <b-table striped hover :items="users" :fields="userTableFields">
                                        <template #cell(actions)="data">
                                            <b-link class="m-1" href="#" @click="unassignUser(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                        </template>
                                    </b-table>
                                </div>

                                <vue-bootstrap-typeahead
                                    :data="availableUsers"
                                    :placeholder="$t(`cfbcreate.placeholder_searchuser`)"
                                    v-model="userSearch"
                                    :serializer="d => d.name + ' - ' + d.company + ' - ' + d.product_group"
                                    @hit="selectedUser = $event" />

                                <br />
                                <b-button class="button-margin" variant="primary" @click="addUserToAuction"><b-icon icon="person-plus" />{{ $t("cfbcreate.btn_adduser") }}</b-button>
                                <p>{{ $t("cfbcreate.adduser_notes") }}</p>

                                <template #footer>
                                    <b-form-checkbox v-model="auction.userlist_checked" name="check-button" switch>
                                        Einladungslink für Bieter erstellt...
                                    </b-form-checkbox>
                                </template>
                            </b-card>

                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.userlist_checked" name="switch" switch>
                                        Einladungslink für Bieter erstellt...
                                    </b-form-checkbox>
                                </div>
                            </template>

                            <b-card :title="$t(`cfbcreate.card_invitations`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-card-text>
                                    <p>{{ $t("cfbcreate.invitation_note1") }}</p>
                                    <p>{{ $t("cfbcreate.invitation_note2") }}</p>
                                    <b-button class="button-margin" variant="primary" to="/app/invitation/new"><b-icon icon="envelope-open" />{{ $t("invitation.btn_invitation_new") }}</b-button>
                                    <b-button class="button-margin" variant="outline-primary" to="/app/invitations"><b-icon icon="envelope" />{{ $t("cfbcreate.btn_invitations") }}</b-button>
                                </b-card-text>
                            </b-card>
                        </b-overlay>
                    </b-tab>

                    <b-tab :title="$t(`cfbcreate.tab_questionnaire`)" :disabled="!editMode">
                        <b-overlay :show="auction.questionnaires_checked" rounded="lg">
                            <b-card :title="$t(`cfbcreate.card_questionnaire`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-card-text>
                                    <template v-for="questionnaire in relatedQuestionnaires">
                                        <b-row class="mb-2 ml-1" v-bind:key="questionnaire.id">
                                            <b-form-checkbox v-model="questionnaire.related">{{questionnaire.name}}</b-form-checkbox>
                                        </b-row>
                                    </template>
                                </b-card-text>
                                <br />
                                <b-button class="button-margin" variant="primary" to="/app/questionnaires/new"><b-icon icon="journal-plus" />{{ $t("questionnaire.btn_createquestionnaire") }}</b-button>
                                <b-button class="button-margin" variant="outline-primary" to="/app/questionnaires"><b-icon icon="pencil-square" />{{ $t("cfbcreate.btn_editquestionnaire") }}</b-button>
                                
                                <template #footer>
                                    <b-form-checkbox v-model="auction.questionnaires_checked" name="check-button" switch>
                                        Fragebögen erstellt und vollständig zugewiesen...
                                    </b-form-checkbox>
                                </template>

                            </b-card>
                         
                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.questionnaires_checked" name="switch" switch>
                                        Fragebögen erstellt und vollständig zugewiesen...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>
                    </b-tab>

                    <b-tab :title="this.isAuction ? this.$t(`cfbcreate.tab_regulations`) : this.$t(`cfbcreate.tab_esg_regulations`)" :disabled="!editMode">
                        <h4>Leistungsbeschreibungen und Bearbeitungshinweise</h4>
                        <b-overlay :show="auction.quotation_requirements_checked" rounded="lg">    
                            <b-card v-if="isAuction" :title="$t(`cfbcreate.card_quotation_requirements`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-card-text>

                                    <!-- Angebotsform-->
                                    <b-form-group :label="$t(`cfbcreate.offer_form`)" class="d-flex justify-content-between mb-0" label-for="auction-offer-form">
                                        <b-button v-b-toggle.auction_offer_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                        <b-form-textarea v-model="auction.offer_form" id="auction-offer-form" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>  <!-- v-model 'auction_offer_help' nue eingefügt-->
                                        <b-sidebar right id="auction_offer_help" :title="$t(`help.title_offerform`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                            <div class="px-3 py-2">
                                                <b-list-group>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.offer_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.offer_1`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.offer_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.offer_2`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.offer_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.offer_3`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.offer_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.offer_4`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.offer_5") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.offer_5`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.offer_6") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.offer_6`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.offer_7") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.offer_7`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.offer_8") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.offer_8`))"></b-icon></b-list-group-item>
                                                </b-list-group>
                                            </div>
                                        </b-sidebar>

                                    <!-- Angebotsbindefrist-->
                                    <b-form-group :label="$t(`cfbcreate.bid_binding_period`)" class="d-flex justify-content-between mb-0" label-for="auction-bid-binding-period"> 
                                        <b-button v-b-toggle.binding_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                        <b-form-textarea v-model="auction.bid_binding_period" id="auction-bid-binding-period" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                        <b-sidebar right id="binding_help" :title="$t(`help.title_bindingperiod`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                            <div class="px-3 py-2">
                                                <b-list-group>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.binding_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.binding_1`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.binding_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.binding_2`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.binding_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.binding_3`))"></b-icon></b-list-group-item>
                                                    <b-list-group-item button class="d-flex align-items-center">{{ $t("help.binding_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.binding_4`))"></b-icon></b-list-group-item>
                                                </b-list-group>
                                            </div>
                                        </b-sidebar>

                                    <!-- Währung-->
                                    <b-form-group :label="$t(`cfbcreate.currency`)" class="d-flex justify-content-between mb-0" label-for="auction-currency">
                                        <b-button v-b-toggle.currency_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.currency" id="auction-currency" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="currency_help" :title="$t(`help.title_currency`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.currency_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.currency_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.currency_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.currency_2`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Vergabebedingungen-->
                                    <b-form-group :label="$t(`cfbcreate.award_model`)" class="d-flex justify-content-between mb-0" label-for="auction-award-model">
                                        <b-button v-b-toggle.award_model_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.award_model" id="auction-award-model" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="award_model_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.award_model_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.award_model_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.award_model_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.award_model_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.award_model_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.award_model_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.award_model_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.award_model_4`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.award_model_5") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.award_model_5`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>
                                    <br />
                                    <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
                                </b-card-text>
                                <template #footer>
                                    <b-form-checkbox v-model="auction.quotation_requirements_checked" name="check-button" switch>
                                        Angebotsanforderungen vollständig...
                                    </b-form-checkbox>
                                </template>
                            </b-card>
                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.quotation_requirements_checked" name="switch" switch>
                                        Angebotsanforderungen vollständig...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>
                        
                        <b-overlay :show="auction.qualification_requirements_checked" rounded="lg">
                            <!-- Qualitätsanforderungen-->
                            <b-card :title="$t(`cfbcreate.card_qualification_requirements`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-card-text>

                                    <!-- Qualität-->
                                    <b-form-group :label="$t(`cfbcreate.certifications`)" class="d-flex justify-content-between mb-0"  label-for="auction-certifications">
                                        <b-button v-b-toggle.certification_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.certifications" id="auction-certifications" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="15" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="certification_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.certification_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.certification_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.certification_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.certification_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.certification_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.certification_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.certification_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.certification_4`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Lieferantenqualifizierung -->
                                    <b-form-group :label="$t(`cfbcreate.supply_chain`)" class="d-flex justify-content-between mb-0"  label-for="auction-supply-chain">
                                        <b-button v-b-toggle.supply_chain_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.supply_chain" id="auction-supply-chain" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="3" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="supply_chain_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.supply_chain_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.supply_chain_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.supply_chain_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.supply_chain_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.supply_chain_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.supply_chain_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.supply_chain_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.supply_chain_4`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>
                                    <br />
                                    <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
                                </b-card-text>
                                <template #footer>
                                    <b-form-checkbox v-model="auction.qualification_requirements_checked" name="check-button" switch>
                                        Qualifikationsanforderungen vollständig...
                                    </b-form-checkbox>
                                </template>
                            </b-card>
                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.qualification_requirements_checked" name="switch" switch>
                                        Qualifikationsanforderungen vollständig...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>
                        
                        <b-overlay v-if="isAuction" :show="auction.quantityprice_information_checked" rounded="lg">
                            <!-- Mengen- und Preisinformationen-->
                            <b-card :title="$t(`cfbcreate.card_quantityprice_information`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-card-text>

                                    <!-- Mengenangaben-->
                                    <b-form-group :label="$t(`cfbcreate.quantity_details`)" class="d-flex justify-content-between mb-0"  label-for="auction-quantity-details">
                                        <b-button v-b-toggle.auction_quantity_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button> 
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.quantity_details" id="auction-quantity-details" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="auction_quantity_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.quantity_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.quantity_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.quantity_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.quantity_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.quantity_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.quantity_3`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Frachtkosten--> <!-- ÜBERSCHNEIDUNG MIT INCOTERMS??-->
                                    <b-form-group :label="$t(`cfbcreate.freight_costs`)" class="d-flex justify-content-between mb-0"  label-for="auction-freight-costs">
                                        <b-button v-b-toggle.auction_freight_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>     
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.freight_costs" id="auction-freight-costs" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="auction_freight_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.freight_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.freight_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.freight_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.freight_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.freight_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.freight_3`))"></b-icon></b-list-group-item> <!-- ÜBERSCHNEIDUNG MIT INCOTERMS??-->
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Preisgleitklausel-->
                                    <b-form-group :label="$t(`cfbcreate.price_adjustment`)" class="d-flex justify-content-between mb-0" label-for="auction-price-adjustment">
                                        <b-button v-b-toggle.price_adjustment_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>           
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.price_adjustment" id="auction-price_adjustment" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="price_adjustment_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.price_adjustment_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.price_adjustment_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.price_adjustment_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.price_adjustment_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.price_adjustment_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.price_adjustment_3`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Bonusregelung-->
                                    <b-form-group :label="$t(`cfbcreate.bonus_system`)" class="d-flex justify-content-between mb-0" label-for="auction-bonus-system">
                                        <b-button v-b-toggle.bonus_system_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>                
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.bonus_system" id="auction-bonus-system" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    
                                    <!-- Sonstige Preisregelungen-->
                                    <b-form-group :label="$t(`cfbcreate.other_price_regulations`)" label-for="auction-other-price-regulations">
                                        <b-form-textarea v-model="auction.other_price_regulations" id="auction-other-price-regulations" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    </b-form-group>

                                    <b-sidebar right id="bonus_system_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.bonus_system_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.bonus_system_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.bonus_system_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.bonus_system_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.bonus_system_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.bonus_system_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.bonus_system_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.bonus_system_4`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>
                                    <br />
                                    <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
                                </b-card-text>
                                <template #footer>
                                    <b-form-checkbox v-model="auction.quantityprice_information_checked" name="check-button" switch>
                                        Preisinformationen vollständig...
                                    </b-form-checkbox>
                                </template>
                            </b-card>
                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.quantityprice_information_checked" name="switch" switch>
                                        Preisinformationen vollständig...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>

                        <b-overlay :show="auction.logistical_requirements_checked" rounded="lg">
                            <!-- Logistische Anforderungen-->
                            <b-card v-if="isAuction" :title="$t(`cfbcreate.card_logistical_requirements`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-card-text>

                                    <!-- Anlieferung und Logistik -->
                                    <b-form-group :label="$t(`cfbcreate.logistics_process`)" class="d-flex justify-content-between mb-0" label-for="auction-logistics-process">
                                        <b-button v-b-toggle.logistics_process_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.logistics_process" id="auction-logistics-process" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="logistics_process_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.logistics_process_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.logistics_process_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.logistics_process_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.logistics_process_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.logistics_process_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.logistics_process_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.logistics_process_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.logistics_process_4`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.logistics_process_5") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.logistics_process_5`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Verpackung und Etikettierung-->
                                    <b-form-group :label="$t(`cfbcreate.packing_labeling`)" class="d-flex justify-content-between mb-0" label-for="auction-packing-labeling">
                                        <b-button v-b-toggle.packing_labeling_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.packing_labeling" id="auction-packing-labeling" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="packing_labeling_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.packing_labeling_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.packing_labeling_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.packing_labeling_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.packing_labeling_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.packing_labeling_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.packing_labeling_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.packing_labeling_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.packing_labeling_4`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.packing_labeling_5") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.packing_labeling_5`))"></b-icon></b-list-group-item>
                                                
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Lagerhaltung und Bevorratung-->
                                    <b-form-group :label="$t(`cfbcreate.warehousing`)" class="d-flex justify-content-between mb-0" label-for="auction-warehousing">
                                        <b-button v-b-toggle.warehousing_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>         
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.warehousing" id="auction-warehousing" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="warehousing_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.warehousing_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.warehousing_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.warehousing_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.warehousing_2`))"></b-icon></b-list-group-item> 
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Entsorgung-->
                                    <b-form-group :label="$t(`cfbcreate.waste_management`)" class="d-flex justify-content-between mb-0" label-for="auction-waste-management">
                                        <b-button v-b-toggle.waste_management_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>         
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.waste_management" id="auction-waste-management" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="waste_management_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.waste_management_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.waste_management_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.waste_management_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.waste_management_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.waste_management_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.waste_management_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.waste_management_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.waste_management_4`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Retourenprozess-->
                                    <b-form-group :label="$t(`cfbcreate.returns_process`)" class="d-flex justify-content-between mb-0" label-for="auction-returns-process">
                                        <b-button v-b-toggle.returns_process_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>         
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.returns_process" id="auction-returns-process" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="returns_process_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.returns_process_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.returns_process_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.returns_process_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.returns_process_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.returns_process_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.returns_process_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.returns_process_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.returns_process_4`))"></b-icon></b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>
                                    <br />
                                    <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
                                </b-card-text>
                                <template #footer>
                                    <b-form-checkbox v-model="auction.logistical_requirements_checked" name="check-button" switch>
                                        Logistische Anforderungen vollständig...
                                    </b-form-checkbox>
                                </template>
                            </b-card>
                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.logistical_requirements_checked" name="switch" switch>
                                        Logistische Anforderungen vollständig...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>

                        <b-overlay :show="auction.commercial_processing_checked" rounded="lg">
                            <!-- Kaufmännische Abwicklung-->
                            <b-card v-if="isAuction" :title="$t(`cfbcreate.card_commercial_processing`)" class="mb-3 shadow" >
                                <b-card-text>

                                    <!-- Bestellprozess-->
                                    <b-form-group :label="$t(`cfbcreate.ordering_process`)" class="d-flex justify-content-between mb-0" label-for="auction-ordering-process">
                                        <b-button v-b-toggle.ordering_process_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>        
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.ordering_process" id="auction-ordering-process" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="ordering_process_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.ordering_process_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.ordering_process_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.ordering_process_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.ordering_process_2`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Wareneingangsprozess-->
                                    <b-form-group :label="$t(`cfbcreate.goods_receipt_process`)" class="d-flex justify-content-between mb-0" label-for="auction-goods-receipt-process">
                                        <b-button v-b-toggle.goods_receipt_process_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>    
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.goods_receipt_process" id="auction-goods-receipt-process" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="goods_receipt_process_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.goods_receipt_process_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.goods_receipt_process_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.goods_receipt_process_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.goods_receipt_process_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.goods_receipt_process_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.goods_receipt_process_3`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Reklamationsprozess-->
                                    <b-form-group :label="$t(`cfbcreate.complaint_management`)" class="d-flex justify-content-between mb-0" label-for="auction-complaint-management">
                                        <b-button v-b-toggle.complaint_management pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>    
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.complaint_management" id="auction-complaint-management" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="complaint_management" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.complaint_management_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.complaint_management_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.complaint_management_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.complaint_management_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.complaint_management_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.complaint_management_3`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Rechnungsstellung-->
                                    <b-form-group :label="$t(`cfbcreate.invoicing`)" class="d-flex justify-content-between mb-0" label-for="auction-invoicing">
                                        <b-button v-b-toggle.invoicing_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.invoicing" id="auction-invoicing" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="invoicing_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.invoicing_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.invoicing_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.invoicing_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.invoicing_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.invoicing_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.invoicing_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.invoicing_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.invoicing_4`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.invoicing_5") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.invoicing_5`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Bemusterung-->
                                    <b-form-group :label="$t(`cfbcreate.sampling_process`)" class="d-flex justify-content-between mb-0" label-for="auction-sampling-process">
                                        <b-button v-b-toggle.sampling_process_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.sampling_process" id="auction-sampling-process" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="sampling_process_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.sampling_process_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.sampling_process_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.sampling_process_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.sampling_process_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.sampling_process_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.sampling_process_3`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.sampling_process_4") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.sampling_process_4`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.sampling_process_5") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.sampling_process_5`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <br />
                                    <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
                                </b-card-text>
                                <template #footer>
                                    <b-form-checkbox v-model="auction.commercial_processing_checked" name="check-button" switch>
                                        Kaufmännische Abwicklung vollständig...
                                    </b-form-checkbox>
                                </template>
                            </b-card>
                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.commercial_processing_checked" name="switch" switch>
                                        Kaufmännische Abwicklung vollständig...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>

                        <b-overlay :show="auction.tenderterms_checked" rounded="lg">
                            <!-- Vertragsbedingungen-->
                            <b-card v-if="isAuction" :title="$t(`cfbcreate.card_tenderterms`)" class="mb-3 shadow" footer-text-variant="muted" footer-bg-variant="white">
                                <b-card-text>

                                    <!-- Vertragsverlängerung-->
                                    <b-form-group :label="$t(`cfbcreate.contract_extension`)" class="d-flex justify-content-between mb-0" label-for="auction-contract-extension">
                                        <b-button v-b-toggle.contract_extension_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.contract_extension" id="auction-contract-extension" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="contract_extension_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.contract_extension_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.contract_extension_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.contract_extension_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.contract_extension_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.contract_extension_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.contract_extension_3`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Versicherung-->
                                    <b-form-group :label="$t(`cfbcreate.insurances`)" class="d-flex justify-content-between mb-0" label-for="auction-insurances">
                                        <b-button v-b-toggle.insurances_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.insurances" id="auction-insurances" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="insurances_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.insurances_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.insurances_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.insurances_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.insurances_2`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Garantiebestimmung-->
                                    <b-form-group :label="$t(`cfbcreate.guarantee_conditions`)" class="d-flex justify-content-between mb-0" label-for="auction-guarantee-conditions">
                                        <b-button v-b-toggle.guarantee_conditions_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.guarantee_conditions" id="auction-guarantee-conditions" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="guarantee_conditions_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">  
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.guarantee_conditions_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.guarantee_conditions_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.guarantee_conditions_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.guarantee_conditions_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.guarantee_conditions_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.guarantee_conditions_3`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Sonstige kaufmännische Rahmenbedingungen-->
                                    <b-form-group :label="$t(`cfbcreate.commercial_conditions`)" class="d-flex justify-content-between mb-0" label-for="auction-commercial-conditions">
                                        <b-button v-b-toggle.commercials_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.commercial_conditions" id="auction-commercial-conditions" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="commercials_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.commercials_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.commercials_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.commercials_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.commercials_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.commercials_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.commercials_3`))"></b-icon></b-list-group-item>
                                                
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>

                                    <!-- Sonstige Vertragsbedingungen-->
                                    <b-form-group :label="$t(`cfbcreate.contract_terms`)" class="d-flex justify-content-between mb-0" label-for="auction-contract-terms">
                                        <b-button v-b-toggle.terms_help pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                                    </b-form-group>
                                    <b-form-textarea v-model="auction.contract_terms" id="auction-contract-terms" class="overflow-auto mb-3" :no-resize="false" :rows="3" :max-rows="10" style="background-color: #fdfdfd"></b-form-textarea>
                                    <b-sidebar right id="terms_help" :title="$t(`help.assistance`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.terms_1") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.terms_1`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.terms_2") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.terms_2`))"></b-icon></b-list-group-item>
                                                <b-list-group-item button class="d-flex align-items-center">{{ $t("help.terms_3") }}<b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.terms_3`))"></b-icon></b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>
                                    <br />
                                    <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
                                </b-card-text>
                                <template #footer>
                                    <b-form-checkbox v-model="auction.tenderterms_checked" name="check-button" switch>
                                        Vertragsbedingungen vollständig...
                                    </b-form-checkbox>
                                </template>
                            </b-card>
                            <template #overlay>
                                <div class="text-center">
                                    <b-icon icon="check-square" font-scale="2"></b-icon>
                                    <b-form-checkbox v-model="auction.tenderterms_checked" name="switch" switch>
                                        Vertragsbedingungen vollständig...
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-overlay>
                    </b-tab>
                    <b-tab :title="$t(`cfbcreate.tab_notes`)" :disabled="!editMode">
                        <b-card :title="$t(`cfbcreate.card_notes`)" class="mb-3 shadow">
                            <b-card-text>
                                <b-form-group :label="$t(`cfbcreate.auction_notes`)" label-for="auction-notes">
                                    <b-form-textarea v-model="auction.notes" id="auction-notes" :no-resize="false" :rows="25" :max-rows="25" style="background-color: #fdfdfd"></b-form-textarea>
                                </b-form-group>
                            </b-card-text>
                            <br />
                        </b-card>
                        <b-card :title="$t(`cfbcreate.negotiation_notes`)" class="mb-3 shadow">
                            <b-card-text>
                                <b-form-group :label="$t(`cfbcreate.negotiation_notes`)" label-for="negotiation-notes">
                                    <b-form-textarea v-model="auction.negotiation_note" id="negotiation-notes" :no-resize="false" :rows="5" style="background-color: #fdfdfd"></b-form-textarea>
                                </b-form-group>
                            </b-card-text>
                            <br />
                        </b-card>
                        <b-card :title="$t(`cfbcreate.award_notes`)" class="mb-3 shadow">
                            <b-card-text>
                                <b-form-group :label="$t(`cfbcreate.award_notes`)" label-for="award-notes">
                                    <b-form-textarea v-model="auction.award_note" id="award-notes" :no-resize="false" :rows="5" style="background-color: #fdfdfd"></b-form-textarea>
                                </b-form-group>
                            </b-card-text>
                            <br />
                        </b-card>
                    </b-tab>
                    
                </b-tabs>
                <br />
                <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("cfbcreate.btn_save") }}</b-button>
            </b-form>
        </div>

        <AuctionItemModal ref="auctionItemModal" :savedCallback="reloadAuctionItemList" :auction="auction"/>

        <FileUploadModal ref="fileUploadModal" :savedCallback="reloadFiles" :auction="auction"/>

        <b-modal ref="importModal" hide-footer :title="$t(`cfbcreate.modal_itemimport`)">
            <div class="d-block text-center">
                {{ $t("cfbcreate.modal_notes") }}
            </div>
            <b-form-file ref="excelFileInput" v-model="excelFile" :state="!$v.excelFile.$error ? null : false"  :placeholder="$t(`cfbcreate.modal_placeholder`)"></b-form-file>
            <b-button class="button-margin mt-3" variant="primary" block @click="importExcelFile">{{ $t("cfbcreate.btn_import") }}</b-button>

            <hr class="mt-3">
            <div>
                <div class="d-block mt-4 h3">{{$t(`cfbcreate.import_help_title`)}}</div>
                <div class="d-block mt-2">{{$t(`cfbcreate.import_help_text`)}}</div>
                <b-button class="button-margin mt-3" variant="outline-primary" @click="downloadTemplate">{{$t(`cfbcreate.import_template`)}}</b-button>
            </div>
        </b-modal>
        <b-modal ref="confirmationModal" hide-footer :title="$t(`cfbcreate.modal_delete_title`)">
            <div class="d-block text-center">
                <p >{{ $t("cfbcreate.confirmation_text_1") }} <strong>'{{auctionItemToDelete.name}}'</strong> {{ $t("cfbcreate.confirmation_text_2") }}</p>
            </div>
            <b-button class="button-margin mt-3" variant="outline-danger" block @click="deleteAuctionItem">{{ $t("cfbcreate.btn_delete") }}</b-button>
        </b-modal>
        <b-modal ref="importErrorModal" :title="$t(`cfbcreate.import_error_title`)" v-b-modal.modal-center ok-only header-bg-variant="warning" footer-bg-variant="warning">
            <p >{{ $t("cfbcreate.import_error_text") }} {{ errorMessage }}</p>
        </b-modal>
    </div>
</template>
<script>
import {_} from 'vue-underscore';
import AuctionItemModal from './modules/CreatePositionModal';
import FileUploadModal from './modules/FileUploadModal';

import callForBidsService from '../../../../services/CallForBidsService';
import questionnaireService from '../../../../services/QuestionnaireService';
import importService from '../../../../services/ImportService';
import DownloadService from '../../../../services/DownloadService';
import {mapGetters, mapActions} from 'vuex';
import numberHelper from '../../../../helpers/numberHelper';
import objectHelper from '../../../../helpers/objectHelper';
import { dateHelper } from '../../../mixins/dateHelper';
import { helpers, required, numeric, decimal} from 'vuelidate/lib/validators';

export default {
    name: 'callforbids-createedit',
    components: {AuctionItemModal, FileUploadModal},
    mixins: [dateHelper],
    data() {
        return {
            editMode: false,
            excelFile: null,
            selectedOrganizer: null,
            doneLoading: false,
            userSearch: '',
            users: [],
            availableUsers:[],
            selectedUser: [],
            auctionItemToDelete: {},
            auction: {
                users: [],
                precision: 2,
                extend_after_bid: 0,
                start_date_time: "08:00",
                end_date_time: "20:00"
            },
            currentAuctionItem: {},
            relatedQuestionnaires: [],
            currentAuctionItemEditMode: false,
            currentFile: {
                name: '',
            },
            editingFile: false,
            isAuction: false,
            errorMessage: '',
            isClipboard: true,
            isCheck: false
        }
    },
    created() {
        this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
        return this.fetchOrganizers()
            .then(() => {
                this.$store.commit('loading/setLoading', false);

                if(this.$route.params.id) {
                    this.$store.commit('loading/setLoading', true);
                    callForBidsService.fetch(this.$route.params.id)
                        .then(data => {
                            if (data.data.success === false)
                            {
                                this.$notify({
                                    group: 'global',
                                    title: this.$t("cfbcreate.load_error_title"),
                                    type: 'error',
                                    text: data.data.message
                                });
                            } else {
                                this.setAuction(data.data);
                                this.editMode = true;
                                this.doneLoading = true;
                                this.selectedOrganizer = this.auction.organizer ? this.auction.organizer.id : null;
                                this.$store.commit('loading/setLoading', false);

                                return this.fetchAssignedUsers()
                                    .then(() => {
                                        return questionnaireService.fetchForAuctions(this.auction.id)
                                            .then(response => {
                                                this.relatedQuestionnaires = response.data;
                                            });
                                    });
                                }
                        });

                }else {
                    this.doneLoading = true;

                }
            });
    },
    validations() {
        let validations = {
            auction: {
                title: {
                    required
                },
                start_date: {
                    required
                },
                end_date: {
                    required
                },
                contact: {
                    required
                }
            },
            selectedOrganizer: {
                required
            },
            excelFile: {
                required
            }
        };

        if(this.isAuction) {
            validations['auction']['extend_after_bid'] = { required }
        }

        return validations;
    },
    methods: {
        ...mapActions({
            fetchOrganizers: 'callForBids/fetchOrganizers'
        }),
        setAuction(auction) {
            auction.start_date = this.$moment(auction.start_date);
            auction.start_date_date = auction.start_date.clone().startOf('day').toDate();
            auction.start_date_time = auction.start_date.format('HH:mm');
            auction.end_date = this.$moment(auction.end_date);
            auction.end_date_date = auction.end_date.clone().startOf('day').toDate();
            auction.end_date_time = auction.end_date.format('HH:mm');
            this.auction = auction;
        },
        async onSubmit() {
            this.auction.start_date = this.dateTimeCombine(this.auction.start_date_date, this.auction.start_date_time);
            this.auction.end_date = this.dateTimeCombine(this.auction.end_date_date, this.auction.end_date_time);

            // TODO fix errors not resetting
            this.$v.auction.start_date.$touch();
            this.$v.auction.end_date.$touch();
            this.$v.$touch();
            if(this.$v.auction.$error) {
                return;
            }

            if(!this.editMode) {
                this.create();
            }else {
                this.update();
            }
        },
        create() {
            this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
            this.auction.organizer = this.selectedOrganizer;

            this.$store.commit('loading/setLoading', true);
            if(this.isAuction) {
                this.auction.bids_active = true;
            }
            callForBidsService.create(this.auction)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);

                    if(response.status === undefined || response.data.success === true) {
                        this.setAuction(response.data);
                        this.editMode = true;

                        this.$notify({
                            group: 'global',
                            title: this.editType + this.$t("cfbcreate.notify_success_title"),
                            type: 'success',
                            text: this.$t("cfbcreate.notify_success_message1") + this.editType + this.$t("cfbcreate.notify_success_message2")
                        });

                        questionnaireService.fetchForAuctions(this.auction.id)
                        .then(response => {
                            this.relatedQuestionnaires = response.data;
                        });

                    }else {
                        this.$notify({
                        group: 'global',
                        title: this.$t("cfbcreate.notify_error_title") + this.editType,
                        type: 'error',
                        text: response.data.message
                    });
                    }
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);

                    this.$notify({
                        group: 'global',
                        title: this.$t("cfbcreate.notify_error_title") + this.editType,
                        type: 'error',
                        text: error.message
                    });
                });
        },
        update() {
            this.auction.organizer = this.selectedOrganizer
            this.auction.relatedQuestionnaires = this.relatedQuestionnaires;

            this.$store.commit('loading/setLoading', true);

            callForBidsService.update(this.auction.id, this.auction)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);

                    if((response.success === undefined) && ((response.data.success === undefined) || (response.data.success === true))) {
                        this.setAuction(response.data);
                        this.editMode = true;

                        this.$notify({
                            group: 'global',
                            title: this.editType + this.$t("cfbcreate.notify_correction_title"),
                            type: 'success',
                            text: this.$t("cfbcreate.notify_correction_message1") + this.editType +this.$t("cfbcreate.notify_correction_message2")
                        });

                    } else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("cfbcreate.notify_correction_error") + this.editType,
                            type: 'error',
                            text: (response.data === undefined) ? response.message : response.data.message,
                        });
                    }
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);

                    this.$notify({
                        group: 'global',
                        title: this.$t("cfbcreate.notify_correction_error") + this.editType,
                        type: 'error',
                        text: error.message
                    });
                });
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
            this.$notify({
                group: 'global',
                title: 'In die Zwischenablage kopiert',
                type: 'success',
                text: this.text,
                autoHideDelay: 1500
            });
        },
        reloadAuctionItemList() {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.listAuctionItems(this.auction.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.auction.items = response.data;
                })
        },
        reloadFiles() {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.listFiles(this.auction.id)
                .then(response => {
                    this.auction.files = response.data;
                    this.$store.commit('loading/setLoading', false);
                });
        },
        showNewItemForm() {
            this.currentAuctionItemEditMode = false;
            this.$refs.auctionItemModal.setAuctionItem({});
            this.$refs.auctionItemModal.showModal();
        },
        findItemByOrder(order) {
            for(var i=0; i< this.auction.items.length; i++) {
                if(this.auction.items[i].order == order) {
                    return this.auction.items[i];
                }
            }
        },
        moveItemUp(order) {
            var itemToMoveUp = this.findItemByOrder(order);
            var itemToMoveDown = this.findItemByOrder(order-1);

            itemToMoveUp.order = order-1;
            itemToMoveDown.order = order
        },
        moveItemDown(order) {
            var itemToMoveDown = this.findItemByOrder(order);
            var itemToMoveUp = this.findItemByOrder(order+1);

            itemToMoveDown.order = order+1;
            itemToMoveUp.order = order;
        },
        showFileForm() {
            this.$refs.fileUploadModal.showModal();
        },   
        editAuctionItem(item) {
            var copiedItem = objectHelper.copy(item);
            copiedItem.actual_value = copiedItem.actual_value ? numberHelper.toCurrency(copiedItem.actual_value, this.auction.precision) : undefined;
            copiedItem.price_alert_value_buyer = copiedItem.price_alert_value_buyer ? numberHelper.toCurrency(copiedItem.price_alert_value_buyer, this.auction.precision) : undefined;
            copiedItem.price_alert_value_supplier = copiedItem.price_alert_value_supplier ? numberHelper.toCurrency(copiedItem.price_alert_value_supplier, this.auction.precision) : undefined;
            copiedItem.lowest_bid = copiedItem.lowest_bid ? numberHelper.toCurrency(copiedItem.lowest_bid, this.auction.precision) : undefined;

            this.$refs.auctionItemModal.setAuctionItem(copiedItem);
            this.$refs.auctionItemModal.showModal();
            this.$refs.auctionItemModal.enterEditMode();
        },
        deleteFile(item) {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.deleteFile(item.id)
                .then(callForBidsService.listFiles(this.auction.id)
                        .then(response => {
                            this.auction.files = response.data;
                            this.$store.commit('loading/setLoading', false);
                        }))
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);
                    this.$notify({
                        group: 'global',
                        title: this.$t("cfbcreate.notify_deletefile_title"),
                        type: 'error',
                        text: error.message
                    });
                });

        },
        downloadFile(item) {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.downloadFile(item)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    const url = window.URL.createObjectURL(new Blob([response], {type: item.mimetype}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        editFile(item) {
            this.$refs.fileUploadModal.showModal();
            this.$refs.fileUploadModal.setFileItem(JSON.parse(JSON.stringify(item)));
            this.$refs.fileUploadModal.enterEditMode();
        },
        searchUser(query) {
            callForBidsService.searchUser(query)
                .then(response => {
                    this.availableUsers = response.data;
                });
        },
        addUserToAuction() {
            if(this.selectedUser) {
                this.$store.commit('loading/setLoading', true);

                callForBidsService.addUser(this.auction.id, this.selectedUser)
                    .then(response => {
                        this.selectedUser = null;
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {

                            this.$notify({
                                group: 'global',
                                title: this.$t("cfbcreate.notify_addusererror_title"),
                                type: 'error',
                                text: response.data.message
                            });

                            return;
                        }
                        this.fetchAssignedUsers();
                        this.userSearch = '';
                        this.selectedUser = null;
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);
                        this.$notify({
                            group: 'global',
                            title: this.$t("cfbcreate.notify_addusererror_title"),
                            type: 'error',
                            text: error.message
                        });
                    });
            }
        },
        fetchAssignedUsers() {
            return callForBidsService.getAssignedUsers(this.auction.id)
                .then(response => {
                    this.users = response.data;
                });
        },
        unassignUser(user) {
            this.$store.commit('loading/setLoading', true);
            return callForBidsService.unassignUser(this.auction.id, user)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    return this.fetchAssignedUsers();
                });
        },
        showImport() {
            this.excelFile = null;
            //this.$refs.excelFileInput.reset();
            this.$refs.importModal.show();
        },
        importExcelFile() {
            this.$v.excelFile.$touch();
            if (this.$v.excelFile.$error) {
                this.$notify({
                            group: 'global',
                            title: 'Import',
                            type: 'error',
                            text: 'Datei auswählen.',
                        });
                return;
            }

            importService.importAuctionItem(this.excelFile, this.auction.id)
                .then(response => {
                    if(response.data.success) {
                        this.$refs.excelFileInput.reset();
                        this.$refs.importModal.hide();
                        this.$notify({
                            group: 'global',
                            title: this.$t("cfbcreate.notify_itemimportsuccess_title"),
                            type: 'success',
                            text: this.$t("cfbcreate.notify_itemimportsuccess_message")
                        });

                        this.$store.commit('loading/setLoading', true);
                        return callForBidsService.listAuctionItems(this.auction.id)
                            .then(response => {
                                this.$store.commit('loading/setLoading', false);
                                this.auction.items = response.data;
                            })
                    } else {
                        this.errorMessage = response.data.message;
                        this.$refs.importErrorModal.show();
                        this.$refs.importModal.hide();
                    }
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    this.$refs.importErrorModal.show();
                });
        },
        toCurrency(value) {
            return numberHelper.toCurrency(value, this.auction.precision);
        },
        requestDeleteAuctionItem(item) {
            this.auctionItemToDelete = item;
            this.$refs.confirmationModal.show();
        },
        deleteAuctionItem() {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.deleteAuctionItem(this.auctionItemToDelete.id)
                .then(response => {
                    this.$refs.confirmationModal.hide();
                    this.auctionItemToDelete = {};

                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("cfbcreate.notify_itemdeletesuccess_title"),
                            type: 'success',
                            text: this.$t("cfbcreate.notify_itemdeletesuccess_message")
                        });


                        callForBidsService.listAuctionItems(this.auction.id)
                            .then(response => {
                                this.$store.commit('loading/setLoading', false);
                                this.auction.items = response.data;
                            });

                    }else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("cfbcreate.notify_itemdeleteerror_title"),
                            type: 'error',
                            text: response.data.message
                        });
                        this.$store.commit('loading/setLoading', false);
                    }
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);
                    this.$notify({
                            group: 'global',
                            title: this.$t("cfbcreate.notify_itemdeleteerror_title"),
                            type: 'error',
                            text: this.$t("cfbcreate.notify_itemdeleteerror_message")
                        });
                    this.$refs.confirmationModal.hide();
                    this.auctionItemToDelete = {};
                });
        },
        moveFileUp(item) {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.moveFileUp(item)
                .then(response => {

                    return callForBidsService.listFiles(this.auction.id)
                            .then(response => {
                                this.auction.files = response.data;
                                this.$store.commit('loading/setLoading', false);
                            });
                });
        },
        moveFileDown(item) {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.moveFileDown(item)
                .then(response => {
                    return callForBidsService.listFiles(this.auction.id)
                            .then(response => {
                                this.auction.files = response.data;
                                this.$store.commit('loading/setLoading', false);
                            });
                });
        },
        downloadTemplate() {
            this.$store.commit('loading/setLoading', true);
            importService.downloadTemplate('Importvorlage_Positionen.xlsx')
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    DownloadService.processDownload(response.data, 'Importvorlage_Positionen.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                });
        }
    },
    computed: {
        ...mapGetters({
            organizers: 'callForBids/organizers',
            users: 'contracts/users',
            hasRole: 'auth/hasRole'
        }),
        editType() {
            return this.isAuction ? this.$t("cfbcreate.auction") : this.$t("cfbcreate.tender");
        },
        itemTableFields() {
            return [
                {   key: 'id',                  label: 'Id'                                                     },
                {   key: 'order',               label: this.$t(`cfbcreate.label_item`)                          },
                {   key: 'name',                label: this.$t(`cfbcreate.label_name`)                          },
                {   key: 'description',         label: this.$t(`cfbcreate.label_spec`)                          },
                {   key: 'amount',              label: this.$t(`cfbcreate.label_amount`), formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }          },
                {   key: 'unit',                label: this.$t(`cfbcreate.label_unit`)                          },
                {   key: 'price_unit',          label: this.$t(`cfbcreate.label_priceunit`)                     },
                {   key: 'lowest_bid',          label: this.$t(`cfbcreate.label_lowestbid`)                     },
                {   key: 'quantity_factor',     label: this.$t(`cfbcreate.label_quantityfactor`), formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }          },
                {   key: 'period_of_demand',    label: this.$t(`cfbcreate.label_periodofdemand`)                },
                {   key: 'commodity_group',     label: this.$t(`cfbcreate.label_commoditygroup`)                },
                {   key: 'actual_value',        label: this.$t(`cfbcreate.label_actualvalue`)                   },
                {   key: 'price_alert_value_buyer',     label: this.$t(`cfbcreate.label_price_alert_value_buyer`)                   },
                {   key: 'price_alert_value_supplier',  label: this.$t(`cfbcreate.label_price_alert_value_supplier`)                },
                {   key: 'actions',             label: this.$t(`cfbcreate.label_actions`)                       },
            ];
        },
        fileTableFields() {
            return [
                {   key: 'name',                label: this.$t(`cfbcreate.label_filename`)            },
                {   key: 'description',         label: this.$t(`cfbcreate.label_filedescription`)     },
                {   key: 'filename',            label: this.$t(`cfbcreate.label_file`)                },
                {   key: 'size',                label: this.$t(`cfbcreate.label_filesize`)            },
                {   key: 'mimetype',            label: this.$t(`cfbcreate.label_filetype`)            },
                {   key: 'actions',             label: this.$t(`cfbcreate.label_actions`)             }
            ];
        },
        userTableFields() {
            return [
                {   key: 'name',                label: this.$t(`cfbcreate.label_username`)      },
                {   key: 'company',             label: this.$t(`cfbcreate.label_company`)       },
                {   key: 'role.name',           label: this.$t(`cfbcreate.label_role`)          },
                {   key: 'actions',             label: this.$t(`cfbcreate.label_actions`)       }

            ];
        },
    },
    watch: {
        userSearch: _.debounce(function(query) {
            if(query && query.length > 1)
                this.searchUser(query)
        } , 200),
        '$route': function() {
            this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
        }
    }
};
</script>
