<template>
    <div class="home">
        <b-img class="mb-3" src="images/bild.png" fluid />
        <span v-html="$t('presentation.team')" />
        <b-card-group class="mb-4" deck>
            <b-card title="Marcus Epping">
                <p class="card-text">
                    Leitung Zentraleinkauf<br/><br/>
                    <a href="mailto: marcus.epping@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 251 493-1348
                </p>
            </b-card>
            <b-card title="Stephan Franke">
                <p class="card-text">
                    Stellv. Einkaufsleitung<br/><br/>
                    <a href="mailto: stephan.franke@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 251 493-1379
                </p>
            </b-card>
        </b-card-group>
        <b-card-group class="mb-4" deck>
            <b-card title="Sascha Strake">
                <p class="card-text">
                    Zentraleinkauf<br/><br/>
                    <a href="mailto: sascha.strake@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 251 493-1358
                </p>
            </b-card>
            <b-card title="Lara Plener">
                <p class="card-text">
                    Zentraleinkauf<br/><br/>
                    <a href="mailto: lara.plener@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 173 394-5908
                </p>
            </b-card>
            <b-card title="André Thiel">
                <p class="card-text">
                    Zentraleinkauf<br/><br/>
                    <a href="mailto: andre.thiel@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 251 493-1330
                </p>
            </b-card>
        </b-card-group>
        <b-card-group class="mb-4" deck>
            <b-card title="André Grotstabel">
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen (Westfleisch-Gruppe)<br/><br/>
                    <a href="mailto: andre.grotstabel@ebnerstolz.de">E-Mail senden</a><br/>
                    Tel. +49 173 250 1031
                </p>
            </b-card>
            <b-card title="Piet Ridderskamp">
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen (Westfleisch-Gruppe)<br/><br/>
                    <a href="mailto: piet.ridderskamp@ebnerstolz.de">E-Mail senden</a><br/>
                    Tel. +49 174 176 7921
                </p>
            </b-card>
        </b-card-group>
        <b-card-group class="mb-4" deck>
            <b-card title="Niclas Heiderhoff">
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen (Westfleisch-Gruppe)<br/><br/>
                    <a href="mailto: niclas.heiderhoff@ebnerstolz.de">E-Mail senden</a><br/>
                    Tel. +49 152 246 03 895  
                </p>
            </b-card>
            <b-card title="Lukas Schönettin">
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen (Westfleisch-Gruppe)<br/><br/>
                    <a href="mailto: lukas.schoenettin@ebnerstolz.de">E-Mail senden</a><br/>
                    Tel. +49 174 884 0238    
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>