import axios from 'axios';

export default {
    evaluateOne(yearId, supplierId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/evaluation/update/' + yearId + '/' + supplierId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    evaluateAll(yearId, supplierId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/evaluation/all/' + yearId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listSectorIndices() {
        return axios.get(window.applicationSettings.apiEndpoint + '/risk/sectorindex/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listCountryIndices() {
        return axios.get(window.applicationSettings.apiEndpoint + '/risk/countryindex/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listSupplierRisks() {
        return axios.get(window.applicationSettings.apiEndpoint + '/risk/evaluation/all')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
}