<template>
    <b-card title="Länderindizes" class="mb-3 shadow">
        <div class="table-scrollable">
                <b-table striped hover
                :items="countryindices"
                :fields="fields"
                responsive="sm"
                >
                </b-table>
        </div>
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';

export default {
    name: 'countryindicestab',
    components: {},
    props: [],
    data() {
        return {
            countryindices: [],
            selectedYear: null,
        }
    },
    created() {
        this.fetchYears();
        this.fetchCountryIndices();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchCountryIndices() {
            this.$store.commit('loading/setLoading', true);
                riskService.listCountryIndices()
                    .then(response => {
                        this.countryindices = response.data;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        fields() {
            return [
                //{ key: 'ID',                        label: 'ID'                                 , sortable: true },
                { key: 'year.year',                 label: 'Jahr'                             , sortable: true },
                { key: 'country.country_iso_3166',  label: 'Länderkürzel'                     , sortable: true },
                { key: 'country.country_name',      label: 'Land'                             , sortable: true, stickyColumn: true,  isRowHeader: true },
                { key: 'country_risk_sum',          label: 'Länderrisiken (Summe)'            , sortable: true, variant: 'info' },
                { key: 'cpi',                       label: 'CPI'                              , sortable: true },
                { key: 'cpi_risk',                  label: 'Bewertung CPIT'                   , sortable: true },
                { key: 'critw',                     label: 'Childrens Rights'                 , sortable: true },
                { key: 'critw_risk',                label: 'Bewertung Childrens Rights'       , sortable: true },
                { key: 'gsi',                       label: 'GSI'                              , sortable: true },
                { key: 'gsi_risk',                  label: 'Bewertung GSI'                    , sortable: true },
                { key: 'nsc',                       label: 'NSC'                              , sortable: true },
                { key: 'nsc_risk',                  label: 'Bewertung NSC'                    , sortable: true },
                { key: 'wjp',                       label: 'WJP'                              , sortable: true },
                { key: 'wjp_risk',                  label: 'BewertungWJP'                     , sortable: true },
                { key: 'ituc',                      label: 'ITUC'                             , sortable: true },
                { key: 'ituc_risk',                 label: 'Bewertung ITUC'                   , sortable: true },
                { key: 'hf',                        label: 'HFI'                              , sortable: true },
                { key: 'hf_risk',                   label: 'Bewertung HFI'                    , sortable: true },
                { key: 'pf',                        label: 'PFI'                              , sortable: true },
                { key: 'pf_risk',                   label: 'Bewertung PFI'                    , sortable: true },
                { key: 'humanrights_risk_sum',      label: 'Risiken Menschenrechte (Summe)'   , sortable: true, variant: 'info' },
                { key: 'epi',                       label: 'EPI'                              , sortable: true },
                { key: 'epi_risk',                  label: 'Bewertung EPI'                    , sortable: true },
                { key: 'environmental_risk_sum',    label: 'Umweltrisiken (Summe)'            , sortable: true, variant: 'info' },
                { key: 'notes',                     label: 'Details'                          , sortable: true, variant: 'secondary' },
            ];
        },
    },
}
</script>